var createjs, AdobeAn;
(function (cjs, an) {

  var p; // shortcut to reference prototypes
  var lib={};var ss={};var img={};
  lib.ssMetadata = [];


  // symbols:
  // helper functions:

  function mc_symbol_clone() {
    var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
    clone.gotoAndStop(this.currentFrame);
    clone.paused = this.paused;
    clone.framerate = this.framerate;
    return clone;
  }

  function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
    var prototype = cjs.extend(symbol, cjs.MovieClip);
    prototype.clone = mc_symbol_clone;
    prototype.nominalBounds = nominalBounds;
    prototype.frameBounds = frameBounds;
    return prototype;
    }



  (lib.Leaf_Neon = function(mode,startPosition,loop) {
    this.initialize(mode,startPosition,loop,{});
    // Layer 1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#B7D94F").s().p("AiQNdQh8g1hUhnQhQhggqh9Qgoh1gFiGQgFiOAdiIQAxjlCDjvQA4hmBKhsQAuhBAUgbQAngzAjgjQAgBNBSBwQAnAzBJBXQBgByA4BXQBIBwAlBzQA6C1AQCqQASDQg0CRQguB+heBbQhmBkh8AVQgkAGgkAAQhcAAhggpg");
    this.shape.setTransform(52.1,90.2);
    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.Leaf_Neon, new cjs.Rectangle(0,0,104.2,180.5), null);


  (lib.Leaf_Teal = function(mode,startPosition,loop) {
    this.initialize(mode,startPosition,loop,{});
    // Layer 1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#3ABEBA").s().p("AiQNdQh8g1hUhnQhQhggqh9Qgoh1gFiGQgFiOAdiIQAxjlCDjvQA4hmBKhsQAuhBAUgbQAngzAjgjQAgBNBSBwQAnAzBJBXQBgByA4BXQBIBwAlBzQA6C1AQCqQASDQg0CRQguB+heBbQhmBkh8AVQgkAGgkAAQhcAAhggpg");
    this.shape.setTransform(52.1,90.2);
    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
  }).prototype = getMCSymbolPrototype(lib.Leaf_Teal, new cjs.Rectangle(0,0,104.2,180.5), null);



  (lib.Symbol1 = function(mode,startPosition,loop) {
    this.initialize(mode,startPosition,loop,{});

    // circle mask (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p("Aj8D8QhohoAAiUQAAiTBohpQBphoCTAAQCUAABoBoQBpBpAACTQAACUhpBoQhoBpiUAAQiTAAhphpg");
    mask.setTransform(-1.1,1.3);

    var maskedShapeInstanceList;

    // Layer 2 -----------------------------------------------------------------////////
    this.instance = new lib.Leaf_Teal();
    this.instance.parent = this;
    this.instance.setTransform(-20.9,-30.8,0.599,0.599,-75,0,0,52,90);
    this.instance.compositeOperation = "multiply";

    maskedShapeInstanceList = [this.instance];

    for(shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).to({regX:51.9,regY:90.2,scaleX:0.45,scaleY:0.45,rotation:-165,x:33.2,y:-31.4},89).wait(1));

    // Layer 3 -----------------------------------------------------------------////////
    this.instance_1 = new lib.Leaf_Teal();
    this.instance_1.parent = this;
    this.instance_1.setTransform(-31.5,40.7,0.371,0.371,-30,0,0,52,90);
    this.instance_1.compositeOperation = "multiply";

    maskedShapeInstanceList = [this.instance_1];

    for(shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).to({regY:90.1,scaleX:0.56,scaleY:0.56,rotation:75,x:13.5,y:-36.7},89).wait(1));

    // Layer 4 -----------------------------------------------------------------////////
    this.instance_2 = new lib.Leaf_Neon();
    this.instance_2.parent = this;
    this.instance_2.setTransform(-40.9,-23.6,0.493,0.493,30,0,0,52.3,90.2);
    this.instance_2.compositeOperation = "multiply";

    maskedShapeInstanceList = [this.instance_2];

    for(shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_2).to({regX:52.1,scaleX:0.46,scaleY:0.46,rotation:60,x:55.9,y:-8.3},89).wait(1));

    // Layer 5 -----------------------------------------------------------------////////
    this.instance_3 = new lib.Leaf_Neon();
    this.instance_3.parent = this;
    this.instance_3.setTransform(-21.7,6.5,0.371,0.371,37.2,0,0,52.1,90.2);
    this.instance_3.compositeOperation = "multiply";

    maskedShapeInstanceList = [this.instance_3];

    for(shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_3).to({regX:52.2,regY:90.5,scaleX:0.49,scaleY:0.49,rotation:-112.8,x:8.1,y:-50.9},89).wait(1));


    // Layer 6 -----------------------------------------------------------------////////
    this.instance_4 = new lib.Leaf_Teal();
    this.instance_4.parent = this;
    this.instance_4.setTransform(6.9,23.2,0.605,0.605,60,0,0,52.1,90.1);
    this.instance_4.compositeOperation = "multiply";

    maskedShapeInstanceList = [this.instance_4];

    for(shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_4).to({regY:90,scaleX:0.51,scaleY:0.51,rotation:150,x:57.1,y:-37.6},89).wait(1));

    // Layer 7 -----------------------------------------------------------------////////
    this.instance_5 = new lib.Leaf_Teal();
    this.instance_5.parent = this;
    this.instance_5.setTransform(20.7,-5.5,0.562,0.562,75,0,0,52.1,90);
    this.instance_5.compositeOperation = "multiply";

    maskedShapeInstanceList = [this.instance_5];

    for(shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_5).to({regX:52,regY:90.2,scaleX:0.38,scaleY:0.38,rotation:-21.5,x:69.7,y:-25.5},89).wait(1));


    // Layer 8
    this.instance_6 = new lib.Leaf_Neon();
    this.instance_6.parent = this;
    this.instance_6.setTransform(17.3,15.4,0.371,0.371,-120,0,0,52.2,90.2);
    this.instance_6.compositeOperation = "multiply";

    maskedShapeInstanceList = [this.instance_6];

    for(shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_6).to({scaleX:0.65,scaleY:0.65,rotation:60,x:33.2,y:14.3},89).wait(1));


  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-36.8,-34.4,71.4,71.4);


  // stage content:
  (lib.canopyLogoAnimationHTML5Canvas = function(mode,startPosition,loop) {
    this.initialize(mode,startPosition,loop,{});

    // timeline functions:
    this.frame_134 = function() {
      /* gotoAndPlay(45);*/
    };

    // actions tween:
    this.timeline.addTween(cjs.Tween.get(this).wait(134).call(this.frame_134).wait(46));

    // o eye middle
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#fff").s().p("AhNBOQggggAAguQAAgtAgggQAgggAtAAQAuAAAfAgQAhAgAAAtQAAAughAgQgfAgguAAQgtAAggggg");
    this.shape.setTransform(270.3,39.6);
    this.timeline.addTween(cjs.Tween.get(this.shape).wait(180));


    // circle mask (mask)
    var mask = new cjs.Shape();
    mask._off = true;
    mask.graphics.p("Aj7D9QhphpAAiUQAAiTBphpQBohoCTAAQCUAABpBoQBoBpAACTQAACUhoBpQhpBoiUAAQiTAAhohog");
    mask.setTransform(271.5,38.7);

    // Layer 14
    this.instance = new lib.Symbol1();
    this.instance.parent = this;
    this.instance.setTransform(304.8,19.6,1.112,1.112,0,0,0,30.8,-17.8);
    this.instance.alpha = 0;
    this.instance._off = true;

    maskedShapeInstanceList = [this.instance];

    for(shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(44).to({_off:false},0).to({scaleX:1.08,scaleY:1.08,y:19.2,alpha:1},27).to({x:305.8},37).to({scaleX:1.11,scaleY:1.11,x:304.8,y:19.6,alpha:0},26).wait(46));

    // Layer 13
    this.instance_1 = new lib.Symbol1();
    this.instance_1.parent = this;
    this.instance_1.setTransform(304.8,19.6,1.112,1.112,0,0,0,30.8,-17.8);

    maskedShapeInstanceList = [this.instance_1];

    for(shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
      maskedShapeInstanceList[shapedInstanceItr].mask = mask;
    }

    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(44).to({scaleX:1.08,scaleY:1.08,y:19.2,alpha:0},27).to({x:305.8},37).to({scaleX:1.11,scaleY:1.11,x:304.8,y:19.6,alpha:1},26).wait(46));

    // can py
    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#009B81").s().p("ADxFQQgUgUAAgdIAAkeQAAhahBhBQhAhAhcgBQhaABhBBAQhBBBAABaIAAAwQAAAdgUATQgUAVgcAAQgcAAgUgVQgVgTAAgdIAAgwQABiTBphoQBohpCTAAQCUAABoBpQBpBoABCTIgBABIAAEdQAAAdgTAUQgVAUgcAAQgcAAgUgUg");
    this.shape_1.setTransform(188.8,39.4);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#009B81").s().p("AkIIyQgcAAgVgUQgUgUAAgcQAAgcAUgUQAVgUAcAAIAXAAQAPgBALgDQAfgJAXgWQAggeAZg/QARgnAfhRIAGgPIgEgGIkbplQgMgcAKgcQALgbAcgNQAcgNAcALQAdAKANAcIDaHXIAEADQAFACAFgGIC2nXQALgbAagLQAagLAaAKQAaALALAaQAMAagLAaIkMK2QggBRgRAoQggBXg5A3Qg0Axg+AQQgbAGgdABg");
    this.shape_2.setTransform(424.5,59.9);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#009B81").s().p("AABFjQiSgMhfhwQhfhxANiRQALiHBghdQBdhbB/gIIAFAAQBNgDBFAZQBHAZA3A2IAcAcQASAXgDAcQgDAZgRAQIgIAGQgVAQgZgDQgYgCgTgSIgYgYQhGhCheAFIgIABQhLAGg4A3Qg9A6gHBTQgHBZA7BGQA7BFBYAIQBjAIBJhFIAYgXQATgTAYgCQAZgCAVAPIAIAHQARAPADAZQADAcgSAXIgcAdQg4A1hGAaQg+AWhEAAIgZgBg");
    this.shape_3.setTransform(34.6,39.6);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#009B81").s().p("AlzD8QhohoAAiTQAAiTBohpQBphoCUAAQB9AABeBMIAAgJQgBgiAdgUQATgOAVAAQAdAAAUAVQATATAAAcIAAFEQABBEATAoQANAdAZAUQAjAZAoAAIAmAAQAcAAAUAVQAUATAAAdQAAAcgUAUQgUAUgcAAIgmAAQhTAAhIg0QgmgbgagqQgyA6hFAfQhHAhhPABQiUAAhphpgAkSiaQhBBBAABaQAABbBBBAQBBBBBbAAQBagBBAg+QA/g/AChZIAAgUQgBhThCg+QhAg8hYAAQhbAAhBBBg");
    this.shape_4.setTransform(119.1,39.5);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#009B81").s().p("AlQIdQgVgUABgcIAAvZQgBgdAVgUQAUgUAdAAQAcAAAUAUQAVAUgBAdIABAHQAugkA3gTQA5gUA8AAQCTAABqBoQBpBogBCTQABCVhpBnQhpBoiTAAIgkgCQgSgBgYgFQgagJgNgaQgNgaAIgaQAJgZAbgNQAagMAbAGQAPACASABQBcgCA/g/QA/g/AAhcQAAhahAhAQhAhBhbAAQhZAAhABAQhBA/AABaIAAK8QAAAcgVAUQgVAVgbAAQgcAAgVgVg");
    this.shape_5.setTransform(352.1,60);

    // this.shape_6 = new cjs.Shape();
    // this.shape_6.graphics.f("red").s().p("AlQIdQgUgUgBgcIAAvZQABgdAUgUQAUgUAdAAQAcAAAUAUQAVAUAAAdIAAAHQAugkA3gTQA5gUA8AAQCTAABpBoQBqBoAACTQAACVhqBnQhoBoiUAAIgkgCQgQgBgZgFQgagJgNgaQgNgaAJgaQAIgZAbgNQAagMAbAGQAPACARABQBdgCA/g/QA/g/AAhcQAAhahAhAQhAhBhbAAQhZAAhABAQhBA/AABaIAAK8QAAAcgVAUQgVAVgbAAQgcAAgVgVg");
    // this.shape_6.setTransform(352.1,60);

    this.timeline.addTween(
      cjs.Tween.get({}).to({
        state:[
          {t:this.shape_5},
          {t:this.shape_4},
          {t:this.shape_3},
          {t:this.shape_2},
          {t:this.shape_1}
        ]
      }).to({
        state:[
          {t:this.shape_5},
          {t:this.shape_4},
          {t:this.shape_3},
          {t:this.shape_2},
          {t:this.shape_1}
        ]
      },179).wait(1)
    );

    // o bg
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#fff").s().p("Aj7D9QhphpAAiUQAAiTBphpQBohoCTAAQCUAABpBoQBoBpAACTQAACUhoBpQhpBoiUAAQiTAAhohog");
    this.shape.setTransform(271.5,38.7);
    this.timeline.addTween(cjs.Tween.get(this.shape).wait(180));

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(237.2,53.3,456.8,127.9);
  // library properties:
  lib.properties = {
    id: '0AD8ACEEA4184FC6B6BFA09D21897239',
    width: 470,
    height: 130,
    fps: 18,
    color: "#FFFFFF",
    opacity: 1.00,
    manifest: [],
    preloads: []
  };



  // bootstrap callback support:

  (lib.Stage = function(canvas) {
    createjs.Stage.call(this, canvas);
  }).prototype = p = new createjs.Stage();

  p.setAutoPlay = function(autoPlay) {
    this.tickEnabled = autoPlay;
  };
  p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()); };
  p.stop = function(ms) {
    if(ms){ this.seek(ms); }
    this.tickEnabled = false;
  };
  p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); };
  p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; };

  p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; };

  an.bootcompsLoaded = an.bootcompsLoaded || [];
  if(!an.bootstrapListeners) {
    an.bootstrapListeners=[];
  }

  an.bootstrapCallback=function(fnCallback) {
    an.bootstrapListeners.push(fnCallback);
    if(an.bootcompsLoaded.length > 0) {
      for(var i=0; i<an.bootcompsLoaded.length; ++i) {
        fnCallback(an.bootcompsLoaded[i]);
      }
    }
  };

  an.compositions = an.compositions || {};
  an.compositions['0AD8ACEEA4184FC6B6BFA09D21897239'] = {
    getStage: function() { return exportRoot.getStage(); },
    getLibrary: function() { return lib; },
    getSpriteSheet: function() { return ss; },
    getImages: function() { return img; }
  };

  an.compositionLoaded = function(id) {
    an.bootcompsLoaded.push(id);
    for(var j=0; j<an.bootstrapListeners.length; j++) {
      an.bootstrapListeners[j](id);
    }
  };

  an.getComposition = function(id) {
    return an.compositions[id];
  };
})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
